// extracted by mini-css-extract-plugin
export var CarrouselWrapper2 = "GraffitiDynamics-module--CarrouselWrapper2--7tBUm";
export var DescriptionWrapper = "GraffitiDynamics-module--DescriptionWrapper--W-WJY";
export var DownloaderWrapper = "GraffitiDynamics-module--DownloaderWrapper--LDgBE";
export var EventWrapper = "GraffitiDynamics-module--EventWrapper--BUrk+";
export var ImageWrapper = "GraffitiDynamics-module--ImageWrapper--0c+nh";
export var ImagesWrapper = "GraffitiDynamics-module--ImagesWrapper--FRCnm";
export var MobileTile = "GraffitiDynamics-module--MobileTile--u1++6";
export var PdpWrapper = "GraffitiDynamics-module--PdpWrapper--1sMeP";
export var PhotosWrapper = "GraffitiDynamics-module--PhotosWrapper--5Box-";
export var TitleWrapper = "GraffitiDynamics-module--TitleWrapper--H8kwi";
export var Wrapper = "GraffitiDynamics-module--Wrapper--K8AfQ";
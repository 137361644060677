import * as React from "react";
import {
  Wrapper,
  EventWrapper,
  PdpWrapper,
  DescriptionWrapper,
  DownloaderWrapper,
  PhotosWrapper,
CarrouselWrapper2,
  TitleWrapper,
  ImageWrapper,
  MobileTile
} from "./GraffitiDynamics.module.css";
import { GrayTitle } from "./../../../../css/Title.module.css";
import Carrousel from "../../../../components/Carrousel";
import Title from "../../../../components/Title";
import Carousel from 'react-multi-carousel';

import Tile from "./../../../../res/graffitiDynamics/tile.png";
import InSitu1 from "./../../../../res/graffitiDynamics/insitu-1.png";
import InSitu2 from "./../../../../res/graffitiDynamics/insitu-2.png";
import InSitu3 from "./../../../../res/graffitiDynamics/insitu-3.png";

const isBrowser = typeof window !== "undefined";
const infos ={
  photos: [
    { src: InSitu1, name: "InSitu4" },
    { src: InSitu2, name: "InSitu2" },
    { src: InSitu3, name: "InSitu3" },
  ],
};

const GraffitiDynamics = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <div className={EventWrapper}>
        <img className={PdpWrapper} src={Tile} alt="GraffitiDynamics"/>
        <div className={DescriptionWrapper}>
        <h1 className={TitleWrapper}>GRAFFITI DYNAMICS<h2 style={{paddingLeft: "16px"}}></h2></h1>
        <br />
        <img className={MobileTile} src={Tile} alt="GraffitiDynamics"/>
        <br />
        <p>
        GHOST galerie is pleased to present its new exhibition: Graffiti Dynamics, from June 15 to August 5, 2023, between Paris and Marseille. An immersion in the New York art scene of the 80s and nowadays !

In spring 2023, post-graffiti, graffiti and other street art forms are on show at prestigious institutions such as the Fondation Vuitton and the Palais de Tokyo. These two emblematic venues put the spotlight on an artistic scene created by rebellious youth.

This new exhibition pays tribute to these aerosol masters (Futura 2000, Dondi White, A-one, Daze, Crash, Lee Quinones) with works from the 80s and 90s, photographs by Henry Chalfant, as well as works by artists who have marked the history of the East Village scene (Kenny Scharf, Richard Hambleton, John Fekner), underground art (Rammellzee, Chaz Bojorquez) or the current scene (Invader, Kaws).

But the exhibition has also been designed to offer an immersive, even introspective, experience. In partnership with TV Store, you can relive this artistic effervescence through a double video installation, a journey back in time to the New York of the 80s.  
        </p>
          {/* <a className={DownloaderWrapper} href={DazePdf}download rel="noopener noreferrer" target="_blank">
              Télécharger le communiqué de presse
          </a> */}
        </div>
      </div>
      <br />
        <div className={PhotosWrapper}>
          <Title title={"Vues in situ"} />
          <div className={CarrouselWrapper2}>
          <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
          </div>
        </div>
        <br />
    </div>
  );
};

export default GraffitiDynamics;
